<template>
  <validation-observer #default="{handleSubmit}">
    <b-form
      @submit.prevent="handleSubmit(submitTopic)"
    >
      <b-form-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Topic"
            rules="required|max:200"
          >
            <b-form-group
              label="Topic"
              label-for="topic"
            >
              <b-form-input
                id="topic"
                v-model="topic.title"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Speaker"
            rules="required|max:200|min:3"
          >
            <b-form-group
              label="Speaker"
              label-for="speaker"
            >
              <b-form-input
                id="speaker"
                v-model="topic.speaker"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <inpo-with-live-select
            :inpo-id.sync="topic.entityId"
            label="Entity"
            :is-all-entities-disabled="true"
          />
        </b-col>

        <b-col md="6">
          <live-auto-suggest
            :live-id.sync="topic.session"
            :activity-id.sync="topic.activity_id"
            :activity-name="topic.activityName"
            :entity-id="topic.entityId"
            label="Live"
            :upcoming-live="true"
          />
        </b-col>

        <b-col md="6">
          <date-time
            v-bind="{
              name:'Start Datetime',
              rules:'required',
              id:'start-datetime',
              config:{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: $moment().isAfter(livePeriod.startDate)?'today':livePeriod.startDate,
                maxDate:$moment(livePeriod.endDate).subtract({minutes:1}).format('YYYY-MM-DD HH:mm'),
                clickOpens:!!topic.session},
            }"
            :datetime.sync="topic.start_time"
          />
        </b-col>

        <b-col md="6">
          <date-time
            v-bind="{
              name:'End Datetime',
              rules:'required',
              id:'end-datetime',
              config:{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate:$moment(topic.start_time).add({minutes:1}).format('YYYY-MM-DD HH:mm'),
                maxDate:$moment(topic.start_time).set({hour:23,minute:59}).toDate(),
                clickOpens:!!topic.session
              },
            }"
            :datetime.sync="topic.end_time"
          />
        </b-col>

        <b-col md="6">
          <language-select
            :languages.sync="topic.languages"
            rules="required"
            :is-multiple="false"
          />
        </b-col>

        <b-col md="6">
          <b-form-row>
            <b-col cols="12">
              <upload-image
                id="topic-image"
                label="Image"
                :rules="isAddForm ? 'required' : ''"
                :images.sync="topic.image"
              />
            </b-col>

            <b-col cols="12">
              <images-base-64-preview
                file-id="topic-image"
                :images.sync="topic.image"
              />
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>

      <b-form-row>
        <back />

        <submit-button
          class="ml-auto"
          v-bind="{submit,handleSubmit}"
        />
      </b-form-row>
    </b-form>
  </validation-observer>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'
import LanguageSelect from '@/common/components/common/FormInputs/LanguageSelect.vue'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import UploadImage from '@/common/components/common/FormInputs/UploadImage.vue'
import LiveAutoSuggest from '@/common/components/common/FormInputs/LiveAutoSuggest.vue'
import Back from '@/common/components/common/FormInputs/Back.vue'
import InpoWithLiveSelect from '@/common/components/common/FormInputs/InpoWithLiveSelect.vue'
import ImagesBase64Preview from '@/common/components/common/FormInputs/ImagesBase64Preview.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import liveDatePeriod from '@/common/compositions/Activities/LiveDatePeriod'

export default {
  name: 'TopicForm',
  components: {
    LanguageSelect,
    DateTime,
    UploadImage,
    LiveAutoSuggest,
    Back,
    InpoWithLiveSelect,
    ImagesBase64Preview,
    SubmitButton,
  },
  props: {
    submit: { type: Function, default: () => null },
    topic: { type: Object, default: () => {} },
  },
  computed: {
    isAddForm() {
      return !this.$route.params.id
    },
  },
  watch: {
    'topic.session': function () {
      this.getLiveDatePeriod(this.topic.activity_id)
    },
    'topic.start_time': function () {
      this.$set(this.topic, 'end_time', null)
    },
  },
  created() {
    if (this.topic.activity_id) { this.getLiveDatePeriod(this.topic.activity_id) }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { livePeriod, loading, getLiveDatePeriod } = liveDatePeriod()

    return {
      getValidationState,
      livePeriod,
      loading,
      getLiveDatePeriod,
    }
  },
  methods: {
    submitTopic() {
      this.setTopic()
      this.submit()
    },
    setTopic() {
      this.$emit('update:topic', this.topic)
    },
  },
}
</script>
<style lang="">

</style>
