import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'

export default function liveDatePeriod() {
  const livePeriod = ref({ startDate: null, endDate: null })
  const loading = ref(false)

  const getLiveDatePeriod = activityId => {
    loading.value = true
    axiosInstances.activities.get(`internalops/live/${activityId}/time/range`).then(res => {
      livePeriod.value = { startDate: res.data.start_time, endDate: res.data.end_time }
      loading.value = false
    })
  }

  return {
    livePeriod,
    loading,
    getLiveDatePeriod,
  }
}
